<template>
  <div class="container">
    <div class="invitaion_top" ref="invitaionTop">
      <div class="invitaion_router" @click="goBack">
        <img src="../assets/played_left.png" class="invitaion_back" alt="" />
        <span class="invitaion_title">奖励详情</span>
      </div>
    </div>
    <div class="invitaion_center">
      <div class="reward_tab">
        <span>好友名称</span>
        <span>邀请状态</span>
        <span style="margin-right: 10px">折扣卡</span>
      </div>
      <van-loading v-if="spinner" style="margin-top: 40px" />
      <div class="reward_tab" v-for="item in items" :key="item.id">
        <span style="min-width:100px;text-align: left;">{{ item.username }}</span>
        <span v-if="item.state == 0" style="color: #ff0000;min-width:76px;text-align: left;">进行中</span>
        <span v-if="item.state == 2" style="color: #ff0000;min-width:76px;text-align: left;">进行中</span>
        <span v-if="item.state == 3" style="color: #4ab104;min-width:76px;text-align: left;">进行中</span>
        <span v-if="item.state == 5" style="color: #4ab104;min-width:76px;text-align: left;">待领取</span>
        <span v-if="item.state == 7" style="color: #b5b5b5">{{
          end ? formatTime(item.end) : '已结束'
        }}</span>
        <span v-if="item.state == 11" style="color: #b5b5b5">已结束</span>
        <span
          class="reward_link"
          style="background: linear-gradient(90deg, #d0d0d0 30%, #b5b5b5 100%)"
          v-if="item.state == 0"
          >不可领</span
        >
        <span
          class="reward_link"
          style="background: linear-gradient(90deg, #d0d0d0 30%, #b5b5b5 100%)"
          v-if="item.state == 2"
          >不可领</span
        >
        <span
          class="reward_link"
          style="background: linear-gradient(90deg, #a64d4d 30%, #a62121 100%)"
          v-if="item.state == 3"
          >不可领</span
        >
        <span
          class="reward_link"
          style="background: linear-gradient(90deg, #ff5555 30%, #ff0000 100%)"
          v-if="item.state == 5"
          @click="goReceive(item)"
          >领取</span
        >
        <span
          class="reward_link"
          style="background: linear-gradient(90deg, #ff5555 30%, #ff0000 100%)"
          v-if="item.state == 7"
          >生效中</span
        >
        <span
          class="reward_link"
          style="background: linear-gradient(90deg, #d0d0d0 30%, #b5b5b5 100%)"
          v-if="item.state == 11"
          >已结束</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { timeApi2 } from "@/utils/index";
import { ksort } from "@/utils/ksort";
import { Toast } from "vant";
import { askGetList, askUseCard } from "@/api";
export default {
  data() {
    return {
      items: [],
      sourcePath: null,
      spinner: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.sourcePath = from.path;
      vm.getData();
    });
  },
  mounted() {
    if (judgeBigScreen()) {
      this.$refs.invitaionTop.className = "invitaion_top invitaionTopAndroid";
    }
  },
  methods: {
    goBack() {
      // if (this.sourcePath != "/invitation") {
      //   store.commit("tabbarIn");
      // }
      store.commit("incrementOut");
      this.$router.go(-1);
    },
    formatTime(time) {
      this.hours = Math.floor(time / 3600);
      this.minute = Math.floor(Math.floor(time % 3600) / 60);
      this.second = time % 60;
      this.hours =
        this.hours.toString().length === 1 ? `0${this.hours}` : this.hours;
      this.minute =
        this.minute.toString().length === 1 ? `0${this.minute}` : this.minute;
      this.second =
        this.second.toString().length === 1 ? `0${this.second}` : this.second;
      return this.hours + "时" + this.minute + "分" + this.second + "秒";
    },
    getData() {
      var params = {
        token: this.CYBoxUser.loginToken,
        time: Math.floor(Date.now() / 1000),
      };
      params.sign = ksort(params, "qsdhajasfas123sq");
      askGetList(params).then((res) => {
        if (res.code == 1) {
          var items = res.data;
          items.map(e => {
            if(e.state == 7) {
              e.end = e.endTime - Math.floor(Date.now() / 1000)
            }
          })
          this.items = items;
          this.spinner = false;

          this.items.map((e) => {
            var timer = setInterval(() => {
              if (e.end > 0) {
                e.end = e.end - 1;
              } else {
                e.end = null
                clearInterval(timer);
              }
            }, 1000);
          });
        }
      });
    },
    goReceive(item) {
      var params = {
        token: this.CYBoxUser.loginToken,
        id: item.id,
        time: Math.floor(Date.now() / 1000),
      };
      params.sign = ksort(params, "qsdhajasfas123sq");
      askUseCard(params).then((res) => {
        if (res.code == 1) {
          this.getData();
        } else {
          Toast(res.message);
        }
      });
    },
  },
  computed: {
    ...mapGetters(["CYBoxUser"]),
  },
};
</script>
<style lang="stylus" scoped>
@import '../components/common.styl';

.invitaion_top {
  background: url('../assets/top.png') center;
  background-size: 100% 100%;
  height: calc(242px + constant(safe-area-inset-bottom));
  height: calc(242px + env(safe-area-inset-bottom));
  position: relative;
  display: flex;
  justify-content: center;
}

.invitaion_router {
  height: 42px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: center;
}

.invitaion_back {
  position: absolute;
  height: 20px;
  width: 12px;
  top: calc(12px + constant(safe-area-inset-bottom));
  top: calc(12px + env(safe-area-inset-bottom));
  left: 16px;
}

.invitaion_title {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: calc(10px + constant(safe-area-inset-bottom));
  top: calc(10px + env(safe-area-inset-bottom));
}

.invitaion_center {
  min-height: 200px;
  width: 96%;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 2%;
  top: 12%;
}

.reward_tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
}

.reward_link {
  width: 74px;
  height: 26px;
  line-height: 26px;
  color: #ffffff;
  border-radius: 13px;
  background: linear-gradient(90deg, $themeMin 30%, $themeMax 100%);
}
</style>